import { Disclosure } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import Dropdown from "./Dropdown";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";

function Header() {
  const router = useRouter();

  const locale = router.locale;

  return (
    <header className="fixed-top">
      <Disclosure
        as="nav"
        className="navbar navbar-expand-lg navbar-light bg-white border-bottom py-0 vstack"
      >
        {({ open, close }) => {
          return (
            <>
              <div className="container" style={{ height: 70 }}>
                <Link href="/" className="navbar-brand" locale={locale}>
                  <div className="hstack gap-2">
                    <Image
                      src="/logo512.png"
                      alt="Logo"
                      width={42}
                      height={42}
                      priority
                    />
                    <span className="fw-semibold d-none d-lg-inline">
                      Myanmar Labour News
                    </span>
                  </div>
                </Link>

                <div
                  className="collapse navbar-collapse me-2"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mb-2 mb-lg-0 me-auto">
                    <li className="nav-item">
                      <Link href={"/news"} className="nav-link" locale={locale}>
                        News
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        href={"/articles"}
                        className="nav-link"
                        locale={locale}
                      >
                        Articles
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        href={"/videos"}
                        className="nav-link"
                        locale={locale}
                      >
                        Videos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        href={"/podcasts"}
                        className="nav-link"
                        locale={locale}
                      >
                        Podcasts
                      </Link>
                    </li>
                    <Dropdown
                      button={
                        <div className="nav-link dropdown-toggle">More</div>
                      }
                      buttonClass="nav-item"
                      placement="bottom-end"
                    >
                      {(open, close) => {
                        return (
                          <ul className="list-group list-group-flush">
                            <Link
                              href={"/surveys"}
                              className="list-group-item list-group-item-action text-decoration-none"
                              onClick={close}
                              locale={locale}
                            >
                              Surveys
                            </Link>
                            <Link
                              href={"/laws"}
                              className="list-group-item list-group-item-action text-decoration-none"
                              onClick={close}
                              locale={locale}
                            >
                              Laws
                            </Link>
                          </ul>
                        );
                      }}
                    </Dropdown>
                  </ul>

                  <div className="hstack gap-2h">
                    <a
                      href="https://www.facebook.com/myanmarlabournews"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="/images/circle_facebook_logo.svg"
                        alt="Facebook"
                        width={30}
                        height={30}
                        priority
                      />
                    </a>
                    <a
                      href="https://twitter.com/mm_labournews"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="/images/circle_twitter_logo.svg"
                        alt="Twitter"
                        width={30}
                        height={30}
                        priority
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/myanmarlabournews"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="/images/circle_instagram_logo.svg"
                        alt="Instagram"
                        width={30}
                        height={30}
                        priority
                      />
                    </a>
                  </div>
                </div>

                <div className="navbar-nav ms-auto">
                  {(locale === "mm" || locale === "en") && (
                    <Dropdown
                      button={
                        <div className="nav-link dropdown-toggle">
                          {locale.toUpperCase()}
                        </div>
                      }
                      buttonClass="nav-item"
                      placement="bottom-end"
                    >
                      {(open, close) => {
                        return (
                          <ul className="list-group list-group-flush">
                            <Link
                              href={"/"}
                              className="list-group-item list-group-item-action text-decoration-none"
                              onClick={close}
                              locale="mm"
                            >
                              Myanmar
                            </Link>

                            <Link
                              href={"/"}
                              className="list-group-item list-group-item-action text-decoration-none"
                              onClick={close}
                              locale="en"
                            >
                              English
                            </Link>
                          </ul>
                        );
                      }}
                    </Dropdown>
                  )}
                </div>

                <Disclosure.Button
                  as={"button"}
                  type="button"
                  className="navbar-toggler ms-3"
                >
                  {open ? (
                    <XMarkIcon width={30} className="text-muted" />
                  ) : (
                    <span className="navbar-toggler-icon"></span>
                  )}
                </Disclosure.Button>
              </div>

              <LazyMotion features={domAnimation} strict>
                <AnimatePresence>
                  {open && (
                    <Disclosure.Panel
                      static
                      as={m.div}
                      layout
                      initial={{
                        height: 0
                      }}
                      animate={{
                        height: "auto"
                      }}
                      exit={{
                        height: 0
                      }}
                      transition={{
                        ease: "linear",
                        height: { duration: 0.2 }
                      }}
                      className={"container overflow-hidden"}
                    >
                      <div className="vstack gap-2 py-2 navbar-nav">
                        <div className="nav-item">
                          <Link
                            href={"/news"}
                            className="nav-link"
                            onClick={() => close()}
                            locale={locale}
                          >
                            News
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            href={"/articles"}
                            className="nav-link"
                            onClick={() => close()}
                            locale={locale}
                          >
                            Articles
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            href={"/videos"}
                            className="nav-link"
                            onClick={() => close()}
                            locale={locale}
                          >
                            Videos
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            href={"/podcasts"}
                            className="nav-link"
                            onClick={() => close()}
                            locale={locale}
                          >
                            Podcasts
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            href={"/surveys"}
                            className="nav-link"
                            onClick={() => close()}
                            locale={locale}
                          >
                            Surveys
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            href={"/laws"}
                            className="nav-link"
                            onClick={() => close()}
                            locale={locale}
                          >
                            Laws
                          </Link>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  )}
                </AnimatePresence>
              </LazyMotion>
            </>
          );
        }}
      </Disclosure>
    </header>
  );
}

export default Header;
