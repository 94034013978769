import Layout from "@/components/Layout";
import "@/styles/globals.css";
import { logEvent } from "firebase/analytics";
import { NextPage } from "next";
import { AppProps } from "next/app";
import NextNProgress from "nextjs-progressbar";
import { ReactElement, ReactNode, useEffect } from "react";

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout =
    Component.getLayout || ((page: ReactElement) => <Layout>{page}</Layout>);

  useEffect(() => {
    const execAnalyticsLog = async () => {
      try {
        if (
          process.env.NEXT_PUBLIC_PROFILE === "prod" &&
          typeof window !== "undefined"
        ) {
          const analytics = (await import("@/common/firebase.config"))
            .analytics;
          logEvent(analytics, "page_view", {
            page_title: document.title,
            page_location: location.href
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    execAnalyticsLog();
  });

  return (
    <>
      <NextNProgress
        color="#36213e"
        startPosition={0.3}
        height={2}
        showOnShallow={true}
        options={{
          showSpinner: false
        }}
      />
      {getLayout(<Component {...pageProps} />)}
    </>
  );
}
