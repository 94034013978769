interface Props {
  title?: string;
  cover?: string;
  description?: string;
  coanical?: string;
  ia?: boolean;
}

export default function MetaTags({
  title = process.env.NEXT_PUBLIC_APP_NAME ?? "",
  cover = `${process.env.NEXT_PUBLIC_BASE_URL ?? ""}/images/cover.png`,
  description = process.env.NEXT_PUBLIC_APP_DESCRIPTION ?? "",
  coanical = process.env.NEXT_PUBLIC_BASE_URL ?? "",
  ia
}: Props) {
  return (
    <>
      <meta name="description" content={description} />

      <meta property="og:url" content={coanical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={cover} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={coanical} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={cover} />
      <meta name="twitter:description" content={description} />

      <link rel="canonical" href={coanical} />

      {ia && (
        <>
          <meta property="og:type" content="article" />
          <meta property="fb:article_style" content="default" />
          <meta
            property="ia:rules_url"
            content={
              process.env.NEXT_PUBLIC_RESOURCE_URL + "/misc/ia_rules.json"
            }
          />
        </>
      )}
    </>
  );
}
