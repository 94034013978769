import Head from "next/head";
import { ReactNode } from "react";
import Footer from "./Footer";
import Header from "./Header";
import MetaTags from "./MetaTags";

interface Props {
  overrideDefaultMeta?: boolean;
  children: ReactNode;
}

export default function Layout({ overrideDefaultMeta, children }: Props) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="fb:pages" content="103932477659152" />
        {!overrideDefaultMeta && (
          <>
            <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
            <MetaTags />
          </>
        )}
      </Head>
      <Header />
      <main className="flex-grow-1" style={{ paddingTop: 70 }}>
        {children}
      </main>
      <Footer />
    </>
  );
}
