import { Popover } from "@headlessui/react";
import { Placement } from "@popperjs/core";
import { ReactNode, useState } from "react";
import { usePopper } from "react-popper";

interface DropdownProps {
  button: ReactNode;
  buttonClass?: string;
  placement?: Placement;
  children: (open: boolean, close?: () => void) => ReactNode;
}

export default function Dropdown({
  button,
  buttonClass,
  placement,
  children
}: DropdownProps) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement ?? "auto"
  });

  return (
    <Popover>
      {({ open, close }) => {
        return (
          <>
            <Popover.Button
              as={"div"}
              role="button"
              ref={setReferenceElement}
              className={`${buttonClass}`}
            >
              {button}
            </Popover.Button>

            <Popover.Overlay className="position-fixed start-0 top-0 end-0 bottom-0" />

            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="bg-white rounded border overflow-hidden"
            >
              {children(open, close)}
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
}
