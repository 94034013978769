/* eslint-disable @next/next/no-img-element */
import { EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/outline";

export default function Footer() {
  const copyRight = `© ${new Date().getFullYear()} Myanmar Labour News`;
  return (
    <footer className="bg-dark">
      <div className="container py-5">
        <div className="row gx-5 gy-4 me-0">
          <div className="col-lg-4">
            <h4 className="fw-semibold text-light mb-3">About us</h4>
            <p className="text-light text-opacity-75 small mb-0">
              To uphold the rule of law needed to improve the social-economic
              life of the working class in Myanmar. To help the transition to
              democracy as media role in the workforce gender equity.
            </p>
          </div>
          <div className="col-lg-4">
            <h4 className="fw-semibold text-light mb-3">Contact us</h4>
            <div className="vstack gap-2 text-light text-opacity-75">
              <div className="hstack gap-2">
                <MapPinIcon width={20} />
                <span className="small">Yangon, Myanmar</span>
              </div>
              <div className="hstack gap-2">
                <EnvelopeIcon width={20} />
                <a
                  href="mailto:myanmarlabournews@gmail.com"
                  className="small text-light text-opacity-75"
                >
                  myanmarlabournews@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h4 className="fw-semibold text-light mb-3">Support us</h4>
            <div className="hstack gap-2">
              <a href="https://bmc.link/myanmarlabournews" target="_blank">
                <img
                  src="/images/bmc-button.svg"
                  alt="Buy me coffee"
                  height="44"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <hr className="text-light-gray my-0" />
      </div>
      <div className="container py-4">
        <div className="d-flex flex-wrap align-items-center justify-content-center gap-4 text-light text-opacity-75">
          <div>{copyRight}</div>

          <div className="ms-sm-auto">
            {/* <small className="me-3 d-none d-sm-inline">Download app:</small> */}
            <a
              href="https://play.google.com/store/apps/details?id=com.mmlabour.news"
              target="_blank"
            >
              <img
                src="/images/google-play-badge.svg"
                alt="Google play"
                height={42}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
